<template>
  <div>
    <ValidationObserver ref="formModalValidate">
      <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
        <b-form-group :label="$t('gsm_no2')">
          <b-form-input
              v-model="studentData.mobile_tel"
              v-mask="'+1 (###)-###-####'"
              :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
          <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <b-button type="submit" @click="sendVerificationSms" variant="primary" class="mr-3">{{
          $t('next')
        }}
      </b-button>
    </ValidationObserver>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";

export default {
  data() {
    return {
      phone: null,
      smsTimer: null,
      smsCode: null,
      isLoading: false
    }

  },
  components: {},
  computed: {
    ...mapGetters({
      studentData: "enrollmentAgreementForm/formData"
    }),

  },


  methods: {
    formatPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/[\s()-]/g, '');
    },
    async sendVerificationSms() {
      const phone = this.formatPhoneNumber(this.studentData.mobile_tel)
      const config = {
        mobile_tel: phone
      }
      const res = await EnrollmentAgreementForm.verifyMobileTel(config)
      if (res.data.success) {
        this.$emit('closeVerificationModal', true)
        this.$emit('showVerifyModal', {
          mobile_tel: this.studentData.mobile_tel,
          student_data: this.studentData
        })
      }
    },
    async clearForm() {
      await this.$store.dispatch('enrollmentAgreementForm/deleteFormData');
    },
  },
};
</script>
<style scoped>
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  .no-button {
    display: none;
  }
}
</style>

